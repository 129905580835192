@mixin teampage-mat-button($theme) {
  @include teampage-call-button();
}

@mixin teampage-call-button() {
  .mat-mdc-outlined-button {
    border-radius: 39px !important;
    padding: 0 !important;
    min-width: 0 !important;

    &:not([disabled]) {
      border-color: currentColor !important;
    }

    .mat-mdc-button-persistent-ripple::before {
      background: currentColor !important;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}
