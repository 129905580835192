@mixin teampage-mat-grid-list($theme) {
  @include teampage-grid-list();
}

@mixin teampage-grid-list() {
  .mat-grid-tile {
    &[teampage-mat-grid-tile] {
      .mat-figure {
        align-items: flex-start !important;
      }
    }
  }
}
