@use '@angular/material' as mat;

@import "../../app/breakpoints.scss";

@mixin teampage-mat-card($theme) {
  @include teampage-grid-card();
  @include teampage-whitepage-card();
  @include personal-page-card();
}

@mixin teampage-grid-card() {
  .mat-mdc-card {
    &[teampage-grid-card] {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border: 1px solid map-get(mat.$gray-palette, 400);

      .mat-mdc-card-actions {
        padding: 0;
      }
    }
  }
}

@mixin teampage-whitepage-card() {
  .mat-mdc-card {
    &[teampage-whitepage-card] {
      border-radius: 15px;
      border: 1px solid map-get(mat.$gray-palette, 400);
      color: map-get(mat.$gray-palette, 600);
      max-width: 940px;
      width: 87%;
      max-height: 300px;
      height: 300px;
      padding-top: 50px;
      box-sizing: border-box;
      text-align: center;
    }
  }
}

@mixin personal-page-card() {
  .mat-mdc-card {
    &[personal-page-card] {
      border-radius: 15px;
      border: 1px solid map-get(mat.$gray-palette, 400);
      max-width: 850px;
      width: 87%;
      padding: 0;
      position: relative;

      .mat-mdc-card-actions {
        margin: 0;
      }

      @include less-than-or-eq-breakpoint(Small) {
        border: unset;
        border-radius: unset;
        max-width: unset;
        width: 100%;
        height: 100vh;
      }
    }
  }
}
