@use '@angular/material' as mat;

//
// CAUTION!
// - If you are importing this file you are probably doing the wrong thing.
// - If you are looking to use color/palette/theme function or variables import ./teampage-theme-variables.scss
// - The CSS generated from this file should be included only once per application!
//

// Our palettes and themes
@import "./teampage-theme-variables.scss";

// Custom color palettes
@import "./teampage-custom-colors.scss";

$teampage-typography: mat.define-typography-config(
  $font-family: 'Roboto, sans-serif'
);

// Teampage custom material styles
@import "./mat-components/mat-button.scss";

@include teampage-mat-button($teampage-theme);

@import "./mat-components/mat-card.scss";

@include teampage-mat-card($teampage-theme);

@import "./mat-components/mat-grid-list.scss";

@include teampage-mat-grid-list($teampage-theme);
@include mat.all-component-typographies($teampage-typography);
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component that you are using.

@include mat.all-component-themes($teampage-theme);

body {
  background: map-get(mat.$gray-palette, 50);
  margin: 0;
  overscroll-behavior-y: none;
}
